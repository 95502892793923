:root {
    --blue-color: #3478F1;
    --light-red-color: #f06548;
    --invoice-text-color: #7D7D7D;
    --red-color: #FF0000;
    --red-dark-color: #DA2F30;
    --orange-color: #FD7545;
    --loader-bg-color: #f2f8fcb3;
    --global-state-disable-bg-color: #0000000d;
    --global-state-disable-color: #00000043;
    --white-color: #FFFFFF;
    --black-color: #000000;
    --offer-code-color: #888A99;
    --offer-status-color: #ff9800;
    --main-color: #0078A3;
    --secondary-color:#FD7545;
    --error-toast-color: #0077a37a;
    --toastify-icon-color-success: #07BC0B !important;
    --toastify-icon-color-error: #ff0000 !important;
    --title-disable-color-edit-offer: #646464;
    --label-disable-color-edit-offer: #909090;
    --csv-file-color: #ADB5BD;
    --file-button-color: #01516e;
    --header-selfcare-profile: #CFD4D9;
    --mvnodashboard-text-color: #495057;
    --vz-dropdown-color:var(--white-color);
    --vz-dropdown-bg:#0078A3;
    --vz-dropdown-hover:#035e7f;
    --light-blue-color: rgba(0, 120, 163, 1);
    --existing-offer-color: #E9EBEC;
    --customer-view-color:#009AD214;
    --success-task-color:#0AB39C;
    --fail-task-color:#F06547;
    --inprogress-task-color:#2A9CDB;
    --image-text-color:#6C757D;
    --pending-task-color:#F6B84B;
    --recent-customer-color:#797979;
    --category-offer-bg-color:#D9D9D9;
    --view-more-color: #005CFF;
    --tool-tip-color:#7B7B7B
}