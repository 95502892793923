
/* FOR LOCAL  */
 .css-pvszrq-MuiButtonBase-root-MuiIconButton-root {
    transform: rotate(90deg) !important;
}

.css-r37x39-MuiButtonBase-root-MuiIconButton-root {
    transform: rotate(90deg) !important;
}

.css-7rwes5-MuiTableCell-root {
    display: none !important;
}

.css-17npdfb-MuiTableCell-root{
    display: none !important;
}

.css-1utrezg-MuiTableCell-root{
    display: none !important;
}
/* for the arrow icon */
/* .css-1kb4auo-MuiTableCell-root{
    display: none ;
} */

 .css-1088au5-MuiTableCell-root{
    display: none ;
}

.css-8x64ye-MuiTableCell-root{
    display: none ;
} 

.css-1dstyzn-MuiTableCell-root {
    display: none !important;
}

.css-1csduzw-MuiTableCell-root {
    display: none !important;
}

/* New version of table */
.css-1ug59k1-MuiButtonBase-root-MuiIconButton-root {
    transform: rotate(90deg) ;
}

.css-1v95a7z {
    transform: rotate(90deg) ;
}

.css-p4lmge-MuiTableCell-root {
    padding: 0px ;
}

.css-1bubb6k-MuiTableCell-root{
    display: none ;
}

.css-1qqp976-MuiTableCell-root{
    display: none ;
}
.css-1r62am-MuiTableCell-root{
    display: none ;
}

.css-18jauja-MuiTableCell-root {
    display: none ;
}

.css-1jpgpam {
    display: none ;
}

.css-1suxerr{
    display: none ;
}

.css-180zix4 {
    display: none ;
}

.css-elm654 {
    display: none ;
}

/* FOR TEST ENV */
/* view icon i.e. 3 dot menu */
.css-171x8ij{
    transform: rotate(90deg) !important;
}
/* for date and time  */
.css-qwdxx6 {
    padding: 8px 2px 8px 14px !important;
}
.css-13ddshk {
    transform: rotate(90deg) ;
}

.css-17xg23s{
    display: none ;
}

.css-1v4nywh{
    display: none ;
}

.css-1gws1yl{
    display: none ;
}

.css-13sws4m{
    display: none ;
}

.css-1nyznh5 {
    display: none ;
}

.css-ef2kln {
    display: none ;
}

.css-13snfga {
    display: none ;
}

.css-1va6qt1 {
    display: none ;
}

.css-1myfg1m {
    display: none ;
}

.css-1epprdq {
    display: none ;
}

.css-1wll77x {
    display: none ;
}

.css-1psjpkr {
    display: none ;
}

.css-70qvj9 {
  font-family: "Inter", sans-serif ;
}

.css-vmwfk{
  font-family: "Inter", sans-serif ;
}

.css-1ujsas3 {
  font-family: "Inter", sans-serif ;
}

.css-198d61m-MuiTypography-root{
  font-family: "Inter", sans-serif ;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  font-family: "Inter", sans-serif ;
}

.css-1jaw3da .MuiFormControlLabel-label.Mui-disabled {
  font-family: "Inter", sans-serif ;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-family: "Inter", sans-serif ;
}

.css-929hxt-MuiInputBase-input-MuiInput-input{
    font-family: "Inter", sans-serif ;
}

.css-5ka3a4{
    font-family: "Inter", sans-serif ;
}